import {
	SettingOutlined,
	FlagOutlined,
	// AreaChartOutlined,
	LockOutlined,
	ApartmentOutlined,
	PartitionOutlined,
	InsertRowLeftOutlined,
	UserAddOutlined,
	CloudServerOutlined,
	LinkOutlined,
	CommentOutlined,
	QuestionCircleOutlined,
	ArrowsAltOutlined,
	// QuestionOutlined,
} from "@ant-design/icons";
import AdminPage from "../pages/AdminPanel";
import Login from "../pages/Authentication/Login";
import Campaigns from "../pages/Campaigns";
// import Dashboard from "../pages/Dashboard";
import Jobs from "../pages/Jobs";
import Organization from "../pages/Organization";
import AdminJobOfferings from "../components/Admin/JobOfferings";
import AdminOrganizations from "../components/Admin/Organizations";
import CreateJobMapping from "../components/Jobs/createjobMapping";
// import JobsListing from "../components/Jobs/jobsListing";
import OrganizationUsers from "../components/Admin/OrganizationUsers";
// import { Questions } from "../pages/Questions";
import AdminTrades from "../components/Admin/Trades";
import OrgCapacity from "../components/Organization/OrgCapacity/index";
// import OrganizationCapacity from "../components/Organization/Capacity";
import OrganizationProfile from "../components/Organization/Profile";
import ThirdPartyInfo from "../components/Organization/ThirdPartyInfo";
import NotificationAccounts from "../components/Organization/Notifications";
// import OrganizationArrivalWindows from "../components/Organization/ArrivalWindows";
import Signup from "../pages/Authentication/SignUp";
import ServiceTitanBusinessUnits from "../components/Organization/ServiceTitanBusinessUnits";
import TradeServiceOfferings from "../components/Organization/TradeServiceOfferings";
import ForgotPassword from "../pages/Authentication/ForgetPassword";
import { BiAnalyse} from "react-icons/bi";
import Questions from "../components/Organization/Questions";
import Analytics from "../pages/Analytics";
import NavigationsFlow from "../components/Organization/NavigationsFlow";
import GenerateCustomURL from "../pages/CustomUrl";

const AuthenticatedRoutes = [
	{
		name: "Jobs",
		routePath: "/jobMappings",
		icon: <ApartmentOutlined />,
		isAdminRestricted: false,
		component: <Jobs />,
	},
	{
		name: "Campaigns",
		routePath: "/campaignsMapping",
		icon: <FlagOutlined />,
		isAdminRestricted: false,
		component: <Campaigns />,
	},
	{
		name: "Settings",
		routePath: "/orgSettings",
		icon: <SettingOutlined />,
		isAdminRestricted: false,
		component: <Organization />,
	},
	{
		name: "Analytics",
		routePath: "/analytics",
		icon: <BiAnalyse />,
		isAdminRestricted: false,
		component: <Analytics/>,
	},
	// {
	// 	name: "Quick Booking Link",
	// 	routePath: "/quickBookingLink",
	// 	icon: <LinkOutlined/>,
	// 	isAdminRestricted: true,
	// 	component: <GenerateCustomURL />,
	// },
	{
		name: "Admin",
		routePath: "/adminSettings",
		icon: <LockOutlined />,
		isAdminRestricted: true,
		component: <AdminPage />,
	},
];

const PublicRoutes = [
	{
		name: "Login",
		routePath: "/",
		icon: "",
		isAdminRestricted: false,
		component: <Login />,
	},
	{
		name: "Signup",
		routePath: "/verify/:token/:type",
		icon: "",
		isAdminRestricted: false,
		component: <Signup />,
	},
	{
		name:"ForgotPassword",
		routePath:"/ForgotPassword",
		isAdminRestricted:false,
		component:<ForgotPassword/>
	},
	{
		name: "About",
		routePath: "/about",
		icon: "",
		isAdminRestricted: false,
		component: <Login />,
	},
];

const AdminTabs = [
	{
		name: "Trades",
		icon: <PartitionOutlined />,
		component: <AdminTrades />,
	},
	{
		name: "Job Types",
		icon: <PartitionOutlined />,
		component: <AdminJobOfferings />,
	},
	{
		name: "Organization",
		icon: <SettingOutlined />,
		component: <AdminOrganizations />,
	},
	{
		name: "Organization Users",
		icon: <UserAddOutlined />,
		component: <OrganizationUsers />,
	},
	{
		name: "Organization Associated Questions",
		icon:<QuestionCircleOutlined />,
		component: <Questions />,
	},
	{
		name: "Organization Navigation Flows",
		icon:<ArrowsAltOutlined />,
		component: <NavigationsFlow/>,
	},
];

export const JobTabs = [
	{
		name: "Create Job Mapping",
		icon: <InsertRowLeftOutlined />,
		component: <CreateJobMapping />,
	},
];

export const OrganizationTabs = [
	{
		name: "Organization Profile",
		icon: <InsertRowLeftOutlined />,
		component: <OrganizationProfile isNewOrg={false} />,
		isCapacityDependent: false,
	},
	{
		name: "Trades & Service Offerings",
		icon: <CloudServerOutlined />,
		component: <TradeServiceOfferings />,
		isCapacityDependent: false,
	},
	{
		name: "API Integration Keys",
		icon: <PartitionOutlined />,
		component: <ThirdPartyInfo />,
		isCapacityDependent: false,
	},
	{
		name: "Notification Accounts",
		icon: <PartitionOutlined />,
		component: <NotificationAccounts />,
		isCapacityDependent: false,
	},
	{
		name: "Organization Capacity",
		icon: <UserAddOutlined />,
		component: <OrgCapacity />,
		isCapacityDependent: true,
	},
	{
		name: "Service Titan Info",
		icon: <CloudServerOutlined />,
		component: <ServiceTitanBusinessUnits />,
		isCapacityDependent: false,
	},
];

export const emailType= {
	Reset: "reset",
	Verification: "verification"
}
export { PublicRoutes, AuthenticatedRoutes, AdminTabs };

export const MAX_FILE_WIDTH = 350;
export const MAX_FILE_HEIGHT = 150;
export const MAX_ALLOWED_QUESTIONS=5
export const MAX_Leads=2500

export const Exclusive_Features=[
	"https://texomateam.com",
	"https://www.cardinalplumbingva.com"
]