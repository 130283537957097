import React from "react";
import { Flex, InputNumber, Select, Switch } from "antd";
import DispatchText from "../../../components/Jobs/dispatchText";

const { Option } = Select;

const Step3Panel = ({
  customerType,
  setCustomerType,
  webAddresses,
  selectedWebAddress,
  setSelectedWebAddress,
  additionalInfo,
  setAdditionalInfo,
  isdispatchFee,
  setIsDispatchFee,
  dispatchDetails,
  handleDispatchTextChange,
}) => {
  return (
    <div>
      <label>Select Customer Type</label>
      <Select
        placeholder="Select Customer Type"
        onChange={(value) => setCustomerType(value)}
        value={customerType}
        style={{ width: "100%", marginBottom: "16px" }}
      >
        <Option value="Existing Customer">Existing Customer</Option>
        <Option value="New Customer">New Customer</Option>
        <Option value="Both">Both</Option>
      </Select>

      <label>Select Web Address</label>
      <Select
        placeholder="Select Web Address"
        onChange={(value) => setSelectedWebAddress(value)}
        value={selectedWebAddress}
        style={{ width: "100%", marginBottom: "16px" }}
      >
        {webAddresses.map((address) => (
          <Option key={address} value={address}>
            {address}
          </Option>
        ))}
      </Select>

      <label>Additional Info</label>
      <Select
        placeholder="Additional Info"
        onChange={setAdditionalInfo}
        style={{ width: "100%", marginBottom: "16px" }}
        value={additionalInfo}
      >
        <Option value={true}>Yes</Option>
        <Option value={false}>No</Option>
      </Select>

      <label>Dispatch Fee</label>
      <Select
        placeholder="Dispatch Fee"
        onChange={(value) => setIsDispatchFee(value)}
        style={{ width: "100%", marginBottom: "16px" }}
        value={isdispatchFee}
      >
        <Option value={true}>Yes</Option>
        <Option value={false}>No</Option>
      </Select>
      <br />

      {isdispatchFee && (
        <>
          <Flex gap={10} style={{ width: "100%" }} align="center">
            <Flex vertical gap={5}>
              <label type="secondary" strong underline>
                Member Dispatch Fee
              </label>
              <InputNumber
                size="middle"
                addonBefore="$"
                defaultValue={0}
                min={0}
                value={dispatchDetails?.member_dispatch_fee}
                onChange={(e) =>
                  handleDispatchTextChange({ member_dispatch_fee: e })
                }
                placeholder="Member Dispatch Fee"
                style={{ width: 150 }}
              />
            </Flex>

            <Flex
              vertical
              gap={5}
              style={{ alignItems: "center", marginBottom: 5 }}
            >
              <label>Member Active</label>
              <Switch
                size="middle"
                checked={dispatchDetails?.member_is_active}
                style={{ marginRight: 20 }}
                onChange={(e) =>
                  handleDispatchTextChange({ member_is_active: e })
                }
              />
            </Flex>
          </Flex>
          <div style={{ boxSizing: "border-box" }}>
            <DispatchText
              dispatch_fee={dispatchDetails.dispatch_fee}
              isDefault={dispatchDetails.use_default_dispatch}
              header={dispatchDetails.dispatch_header}
              body={dispatchDetails.dispatch_body}
              checkboxText={dispatchDetails.dispatch_text}
              onChange={handleDispatchTextChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Step3Panel;
